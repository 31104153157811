import React from 'react';
import { Box, Typography, Button, Stack, IconButton, Tooltip } from '@mui/material';
import { Add, Remove, RestartAlt } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



function RatingComponent({ Scores, year, setYear, initialYear }) {
  const { totalAverageScore, ttmAverageScore, quarterlyScores } = Scores;
  const latestQuarterScore = (quarterlyScores && quarterlyScores[0]?.score) ?? 0;
  const maxYear = 8;
  const minYear = 2;


  const handleDecrement = () => {
    console.log("inside handledec");
    if (year > minYear) {
      setYear((prevValue) => prevValue - 1);
    }
  };

  const handleIncrement = () => {
    console.log("inside handleinc");
    if (year < maxYear) {
      setYear((prevValue) => prevValue + 1);
    }
  };

  const handleReset = () => {
    setYear(initialYear);
  };

  // const getArrowPosition = (value) => `${(value / 10) * 100}%`;
  const getArrowColor = (value) => {
    if (value <= 3) return 'red';
    if (value <= 5) return 'orange';
    // if (value <= 7.5) return 'yellow';
    return 'green';
  };

  const calculatePosition = (score, index, positions) => {
    let pos = (score / 10) * 100;
    const isMobile = window.innerWidth <= 600;
    const minGap = isMobile ? 13 : 5;
    // const minGap = 5; 
    let adjustedPos = pos;

    for (let i = 0; i < positions.length; i++) {
      const prevPos = positions[i];
      if (Math.abs(adjustedPos - prevPos) < minGap) {
        adjustedPos = prevPos + minGap;
      }
    }

    positions.push(adjustedPos);
    return adjustedPos;
  };

  const arrowPositions = [];
  const ttmPos = calculatePosition(ttmAverageScore, 0, arrowPositions);
  const totalPos = calculatePosition(totalAverageScore, 1, arrowPositions);
  const latestPos = calculatePosition(latestQuarterScore, 2, arrowPositions);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        mt: 2,
      }}
    >
      <Box flex="1" textAlign="right" display="flex" alignItems="center" justifyContent="flex-end">
        <Typography variant="h6" sx={{ textAlign: 'center', mb: 2, paddingBottom: 2 }}>
          Zinvest Score
        </Typography>
        <Tooltip title="Zinvest Score is calculated out of 10, based on fundamental datas of various metrics.As of Now Banking Stocks are not accurateand cannot be depended for taking informed decisions.">
          <IconButton
            size="small"
            sx={{
              backgroundColor: 'transparent',
              padding: 0,
              marginLeft: 0.5,
              paddingBottom:0,
              marginBottom:4
            }}
          >
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box
        sx={{
          position: 'relative',
          width: '80%',
          height: 10,
          background: 'linear-gradient(to right, red, orange, yellow, green)',
          borderRadius: 5,
          mt: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: `${ttmPos}%`,
            top: -60,
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              width: 30,
              height: 30,
              backgroundColor: getArrowColor(ttmAverageScore),
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
              color: 'white',
            }}
          >
            {ttmAverageScore?.toFixed(1)}
          </Box>
          <Typography variant="caption" sx={{ fontSize: 10 }}>
            TTM
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            left: `${totalPos}%`,
            top: -60,
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              width: 30,
              height: 30,
              backgroundColor: getArrowColor(totalAverageScore),
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
              color: 'white',
            }}
          >
            {totalAverageScore?.toFixed(1)}
          </Box>
          <Typography variant="caption" sx={{ fontSize: 10 }}>
            Total
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            left: `${latestPos}%`,
            top: -60,
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              width: 30,
              height: 30,
              backgroundColor: getArrowColor(latestQuarterScore),
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
              color: 'white',
            }}
          >
            {latestQuarterScore?.toFixed(1)}
          </Box>
          <Typography variant="caption" sx={{ fontSize: 10 }}>
            Last
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            right: -40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0,
          }}
        >
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            {`Year: ${year}`}
          </Typography>
          <Button size="small" onClick={handleIncrement}
            sx={{
              minWidth: 0,
              padding: 0,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Add />
          </Button>
          <Button size="small" onClick={handleReset}
            sx={{
              minWidth: 0,
              padding: 0,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <RestartAlt />
          </Button>
          <Button size="small" onClick={handleDecrement}
            sx={{
              minWidth: 0,
              padding: 0,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Remove />
          </Button>

        </Box>
      </Box>
    </Box>
  );
}

export default RatingComponent;

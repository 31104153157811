import React, { useState, useEffect, useRef } from 'react';
import { Box ,useMediaQuery} from '@mui/material';

const StickyHeader = ({ children, position = 300, openSidebar, drawerWidth }) => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const handleScroll = () => {
    if (stickyRef.current) {
      const threshold = position;
      const scrollPosition = window.scrollY;

      if (scrollPosition >= threshold) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box ref={stickyRef}>
      {isSticky && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: openSidebar && isDesktop ? `${drawerWidth}px` : 0,  // Adjust position based on sidebar
            right: 0,
            zIndex: 10,
            backgroundColor: 'white',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default StickyHeader;

import React, { useEffect, useRef, useState } from 'react';
import { Chart, CandlestickSeries } from 'lightweight-charts-react-wrapper';
import { CrosshairMode } from 'lightweight-charts';

const MinuteChart = () => {
  const seriesRef = useRef(null);
  const [data, setData] = useState(createInitialMinuteData());

  useEffect(() => {
    let lastClose = data[data.length - 1].close;
    let lastIndex = data[data.length - 1].time; // Last known time as Unix timestamp

    let targetIndex = lastIndex + 105 * 60 + Math.round(Math.random() * 30) * 60; // Target time in seconds
    let targetPrice = lastClose + getRandomPriceChange();
    let ticksInCurrentBar = 0;
    let currentIndex = lastIndex + 60; // Next minute's Unix timestamp in seconds
    let currentBar = {
      open: null,
      high: null,
      low: null,
      close: null,
      time: currentIndex, // Current time as Unix timestamp in seconds
    };
    

    const interval = setInterval(() => {
      const deltaY = targetPrice - lastClose;
      const deltaX = targetIndex - lastIndex;
      const angle = deltaY / deltaX;
      const basePrice = lastClose + (currentIndex - lastIndex) * angle;
      const noise = 0.9 + Math.random() * 0.2;
      const noisedPrice = basePrice * noise;
      mergeTickToBar(noisedPrice);

      // Increment the number of ticks in the current bar
      if (++ticksInCurrentBar === 5) { 
        // move to next bar
        currentIndex += 60;
        currentBar = {
          open: null,
          high: null,
          low: null,
          close: null,
          time: currentIndex,
        };
        ticksInCurrentBar = 0;

        // Reset data if it grows too large
        if (data.length > 5000) {
          reset();
          return;
        }

        // Change trend if targetIndex is reached
        if (currentIndex === targetIndex) {
          lastClose = noisedPrice;
          lastIndex = currentIndex;
          targetIndex = lastIndex + 5 * 60 + Math.round(Math.random() * 30) * 60;
          targetPrice = lastClose + getRandomPriceChange();
        }
      }
    }, 1000); // 1-second interval for emulation

    return () => {
      clearInterval(interval);
    };

    function mergeTickToBar(price) {
      if (currentBar.open === null) {
        currentBar.open = price;
        currentBar.high = price;
        currentBar.low = price;
        currentBar.close = price;
      } else {
        currentBar.close = price;
        currentBar.high = Math.max(currentBar.high, price);
        currentBar.low = Math.min(currentBar.low, price);
      }

      if (seriesRef.current) {
        seriesRef.current.update(currentBar);
        // console.log("Currentbar",currentBar)
      }
    }

    function reset() {
      const initialData = createInitialMinuteData();
      setData(initialData);
      lastClose = initialData[initialData.length - 1].close;
      lastIndex = initialData[initialData.length - 1].time;
      targetIndex = lastIndex + 5 * 60 + Math.round(Math.random() * 30) * 60;
      targetPrice = lastClose + getRandomPriceChange();
      currentIndex = lastIndex + 60;
    }

    function getRandomPriceChange() {
      return Math.random() * 2 - 1; // Random price change in the range of -1 to +1
    }
  }, [data]);

  return (
    <>
      <h1>Realtime Emulation</h1>
      <Chart
        width={800}
        height={400}
        crosshair={{ mode: CrosshairMode.Normal }}
        timeScale={{
          timeVisible: true,
          secondsVisible: true, // Ensure that seconds are visible for more granular updates
          tickMarkFormatter: (time) => {
            const date = new Date(time * 1000); // Convert UNIX timestamp to milliseconds
            return `${date.getHours().toString().padStart(2, '0')}:${date
              .getMinutes()
              .toString()
              .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`; // Format time with seconds
          },
        }}
      >
        <CandlestickSeries
          data={data}
          ref={seriesRef}
          options={{ upColor: 'rgba(0, 150, 136, 0.6)', borderVisible: true }}
        />
      </Chart>
    </>
  );
};

function createInitialMinuteData() {
  const initialData = [];
  const currentTime = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
  let currentPrice = 50; // Starting price

  for (let i = 0; i < 60; i++) { // 60-minute data for initial setup
    const open = currentPrice;
    const close = open + getRandomPriceChange();
    const high = Math.max(open, close) + Math.random() * 0.5;
    const low = Math.min(open, close) - Math.random() * 0.5;
    const bar = {
      time: currentTime - ((60 - i) * 60), // Unix timestamp for each minute in seconds
      open,
      high,
      low,
      close,
    };
    initialData.push(bar);
    currentPrice = close;
  }
  return initialData;
}

function getRandomPriceChange() {
  return Math.random() * 0.2 - 0.1; // Random price change in the range of -0.1 to +0.1
}

export default MinuteChart;

// context/CompanySearchContext.js
import React, { createContext, useContext, useState } from 'react';

const CompanySearchContext = createContext({
  searchQuery: '',
  setSearchQuery: () => {},
});
export const useCompanySearch = () => {
  return useContext(CompanySearchContext);
};

export const CompanySearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [companySymbol, setCompanySymbol] = useState('');
  const [ISIN, setCompanyISIN] = useState('');

  return (
    <CompanySearchContext.Provider value={{ searchQuery, setSearchQuery, companySymbol, setCompanySymbol,ISIN ,setCompanyISIN}}>
      {children}
    </CompanySearchContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuthContext } from '../../context/AuthContext';// Assuming you have a custom AuthContext
import { setToken } from '../../helpers'; // Helper function to set JWT token
import { API } from '../../constant'; // Your API base URL
import { CircularProgress,Box } from '@mui/material';

const provider = 'google';

const GoogleAuthCallback = ({ onAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState('/home');

  // Extract the access_token from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const access_token = queryParams.get('access_token');

  useEffect(() => {
    const authenticateUser = async () => {
      if (access_token) {
        try {
          const response = await axios.get(`${API}/auth/${provider}/callback?access_token=${access_token}`);
          setToken(response.data.jwt);
          setUser(response.data.user);
          navigate(redirectUrl);
        } catch (error) {
          console.error('Error during login:', error);
          setError('Authentication failed. Please try again.');
        }
      }
      setIsLoading(false);
    };

    authenticateUser();
  }, [access_token]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh" 
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>; 
  }

  return null; 
};

export default GoogleAuthCallback;

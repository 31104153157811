import React, { useState, useEffect } from 'react';
import { Container, Paper, TextField, Button, Switch, FormControlLabel, CircularProgress } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Alert from '@mui/material/Alert';

import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { message, Spin, Typography } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { getToken } from "../../helpers";
import UserStrategyForm from './UserStrategyForm';


const StrategyEditor = ({ strategy }) => {
    // const flatFormData = flattenObject(strategy);
    const { attributes } = strategy;
    const { override_data } = attributes;
    // Select only the desired fields from 'attributes' to display
    // const override_data = flattenObject(attributes.override_data)
    const overrideDataString = JSON.stringify(override_data, null, 2);
    const selectedFields = {
        indices: attributes.indices,
        allocation: attributes.allocation,
        status: attributes.status,
        overrideData: overrideDataString

    };

    const formFields = Object.entries(selectedFields).map(([name, value]) => {
        let options = [];
        let type = 'text';

        if (name === 'indices') {
            options = ['nifty', 'bank_nifty', 'sensex', 'fin_nifty', 'midcpnifty'];
            type = 'dropdown';
        } else if (name === 'status') {
            options = ['active', 'inactive'];
            type = 'dropdown';
        }
        return {
            name,
            value,
            options, // Add options array to the form field
            type,
            required: name === 'indices' || name === 'allocation' || name === 'status'
        };
    });

    const [editingEnabled, setEditingEnabled] = useState(false);
    const [formData, setFormDataValues] = useState(formFields);
    const [loading, setLoading] = useState(false);

    const [isChecked, setIsChecked] = useState(false);

    const handleEditClick = (event) => {

        setIsChecked(event.target.checked);
        if (event.target.checked) {
            setEditingEnabled(true);
        } else {
            setEditingEnabled(false);
        }
    };
    const handleDelete = async (event) => {
        event.preventDefault();
        try {
            if (!strategy) {
                throw new Error("Strategy is not defined.");
            }
            const authToken = getToken();
            const response = await fetch(`${API}/app-user-strategies/${strategy.id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                }
            });

            if (response.ok) {
                message.success("Strategy deleted successfully!");
            } else {
                throw new Error(`Failed to delete strategy: ${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            console.error(error);
            // message.error("Error while deleting strategy!");
        } finally {
            setLoading(false);
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const formDataObject = formData.reduce((acc, { name, value }) => {
                acc[name] = value;
                return acc;
            }, {});

            const parsedOverrideData = formDataObject.overrideData ? JSON.parse(formDataObject.overrideData) : {};

            const payload = {
                data: {
                    indices: formDataObject.indices,
                    allocation: formDataObject.allocation,
                    status: formDataObject.status,
                    overrideData: parsedOverrideData, // Assign the parsed object
                }
            };
            const authToken = getToken();
            const response = await fetch(`${API}/app-user-strategies/${strategy.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                message.success("Strategy updated successfully!");
            } else {
                console.error(`Error: ${response.status} - ${response.statusText}`);
                message.error("Failed to update strategy!");
            }
        } catch (error) {
            console.error(error);
            message.error("Error while updating strategy!");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        // eslint-disable-next-line
    }, []);

    return (
        <Paper
            sx={{
                p: 2,
                display: 'fill',
                flexDirection: 'column',
                height: 'auto',
                width: 'auto'
            }}
        >
            <div>
                <FormControlLabel
                    control={

                        <Switch
                            checked={isChecked}
                            onChange={handleEditClick}
                        />
                    }
                    label="Edit"
                />
            </div>
            {formData.map((field, index) => (
                <React.Fragment key={index}>
                    {field.type === 'dropdown' ? (
                        <FormControl fullWidth margin="normal">
                            <InputLabel>{field.label}</InputLabel>
                            <Select
                                value={field.value}
                                onChange={(event) => {
                                    if (editingEnabled) {
                                        const updatedFormData = [...formData];
                                        updatedFormData[index].value = event.target.value;
                                        setFormDataValues(updatedFormData);
                                    }
                                }}
                                disabled={!editingEnabled}
                            >
                                {field.options.map((option, optionIndex) => (
                                    <MenuItem key={optionIndex} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            key={index}
                            name={field.name}
                            label={field.name}
                            value={field.value}
                            onChange={(event) => {
                                if (editingEnabled) {
                                    const updatedFormData = [...formData];
                                    updatedFormData[index].value = event.target.value;
                                    setFormDataValues(updatedFormData);
                                }
                            }}

                            fullWidth
                            margin="normal"
                            multiline
                            disabled={!editingEnabled}
                            required={field.required}
                            rows={Math.ceil(field.value.length / 50)}
                            style={{ width: '40%' }}
                        />
                    )}
                </React.Fragment>
            ))}

            <div style={{ display: 'flex', gap: '1rem' }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!editingEnabled}
                >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>

                <Button
                    onClick={handleDelete}
                    disabled={!editingEnabled}
                    variant="contained"
                    startIcon={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '1px' }} />
                            <DeleteIcon />
                        </div>
                    }>
                    Delete
                    <span style={{ marginRight: '1px' }} />
                </Button>
            </div>
        </Paper>
    );
};


const Strategies = () => {
    const [userStrategies, setUserStrategies] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useAuthContext();
    const [max_strategies_limit, setStrategyLimit] = useState(0)

    const [openModal, setOpenModal] = useState(false);
    const [fabClicked, setFabClicked] = useState(false);




    const handleFabClick = () => {
        if (userStrategies.length <= max_strategies_limit) {
            setOpenModal(true);
            // avoid resizeobserver loop error
            setFabClicked(true);
        } else {
            setOpenModal(false);
        }

    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setFabClicked(false);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const authToken = getToken();
            const response = await fetch(`${API}/app-user-strategies?filters[user][id]=${user.id}&sort=id:asc`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.ok) {
                const responseData = await response.json();
                if (responseData.data.length === 0) {
                    message.info("No strategies available.");
                }
                else {

                    setUserStrategies(responseData.data);
                }
            } else {
                console.error(`Error: ${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {

        fetchData();
        setStrategyLimit(user.max_strategies_limit)
        // eslint-disable-next-line
    }, [user, openModal]);

    if (loading) {
        return <Spin size="large" />;
    }


    return (
        <Container>

            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
            >
                <h4>User Strategies</h4>
            </Typography>

            {!fabClicked && userStrategies.map(strategy => (
                <StrategyEditor
                    key={strategy.id}
                    strategy={strategy}
                />
            ))}


            <div>

                {userStrategies.length <= max_strategies_limit && (
                    <Fab
                        color="primary"
                        aria-label="add"
                        onClick={handleFabClick}
                    // disabled={!}
                    >
                        <AddIcon />
                    </Fab>
                )}

            </div>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Paper style={{ padding: '2rem' }}>
                        <div style={{ maxHeight: '80vh', overflow: 'auto' }}>

                            {
                                userStrategies.length < max_strategies_limit ? (
                                    <UserStrategyForm
                                        fabClicked={fabClicked}
                                        onClose={handleCloseModal}
                                    />
                                ) : (
                                    <Alert severity="warning">Reached Maximum Limit</Alert>
                                )
                            }



                        </div>
                    </Paper>
                </div>
            </Modal>

        </Container>
    );
};

export default Strategies;

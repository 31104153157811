import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// import Title from './Title';
import { message, Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { useState, useEffect, useRef } from "react";
import { getToken } from "../../helpers";
import ResponsiveDatePickers from '../DatePicker';

const calculateProfit = (exitPrice, entryPrice, quantity) => {
  if (exitPrice <= 0 || entryPrice <= 0 || !Number.isInteger(quantity)) {
    return "No value";
  }
  return ((exitPrice - entryPrice) * quantity).toFixed(2);
};



const UserOrders = () => {
  const [userOrders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();
  const authToken = getToken();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const today = new Date().toISOString().split('T')[0];
  const [orderDate,setOrderDate] = useState(today);
  const scrollRef = useRef(0);

  const handleDateChange = (selectedDate) => {
    setOrderDate(selectedDate);
    console.log('order Date:',orderDate);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
    console.log("page", currentPage)
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {

    const fetchOrderData = async () => {
      setLoading(true);
      try {
        // const today = new Date().toISOString().split('T')[0];
        const filters = `filters[user][id]=${user.id}&filters[createdAt][$gte]=${orderDate}`;
        const response = await fetch(`${API}/app-orders?${filters}&sort=id:desc&_limit=25&pagination[page]=${currentPage}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },

        });

        if (response.ok) {
          const responseData = await response.json();
          console.log("user-orders", responseData.data)
          if (responseData.data.length === 0) {
            message.info("No orders available.");
          }
          else {
            const orders = responseData.data;
            // setOrders((prevOrders) => [...prevOrders, ...orders]);
            setOrders(prevOrders => [...prevOrders, ...orders]);

            setTotalPages(responseData.meta.pagination.pageCount);
          }
        } else {
          console.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        // Restore the scroll position after re-render
        window.scrollTo(0, scrollRef.current);
      }
    };

    fetchOrderData();
  }, [user, orderDate,currentPage,authToken]);


  if (loading) {
    return <Spin size="large" />;
  }

  return (
    // <div onScroll={handleScroll} style={{ height: '200px', overflowY: 'scroll' }}>
      <React.Fragment>
        <div className="recent-orders">
        <Typography variant="h6">Recent Orders</Typography>
        </div>
        
        <div style={{ marginLeft: 'auto' }}>
          <ResponsiveDatePickers onDateChange={handleDateChange} />
        </div>
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Entry Price</TableCell>
                <TableCell>Exit Price</TableCell>
                <TableCell>Profit</TableCell>
                <TableCell>Return(%)</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userOrders.map((order) => (
                <TableRow key={order.id}>
                  
                  <TableCell>{order.attributes.entry_price}</TableCell>
                  <TableCell>
                    {order.attributes.exit_price !== null
                      ? order.attributes.exit_price
                      : order.attributes.exit_decision_ltp}
                  </TableCell>

                  <TableCell>
                    {calculateProfit(
                      order.attributes.exit_price !== null
                        ? order.attributes.exit_price
                        : order.attributes.exit_decision_ltp,
                      order.attributes.entry_price,
                      order.attributes.quantity
                    )}
                  </TableCell>
                  <TableCell>
                    {(calculateProfit(
                      order.attributes.exit_price !== null
                        ? order.attributes.exit_price
                        : order.attributes.exit_decision_ltp,
                      order.attributes.entry_price,
                      order.attributes.quantity
                    ) / (order.attributes.entry_price * order.attributes.quantity) * 100).toFixed(2)}
                  </TableCell>

                  <TableCell>{new Date(order.attributes.createdAt).toLocaleString()}</TableCell>



                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {totalPages > 1 && (
          <div>
            {currentPage > 1 && (
              <Button onClick={goToPreviousPage}>Previous Page</Button>
            )}
            {currentPage < totalPages && (
              <Button onClick={goToNextPage}>Next Page</Button>
            )}
          </div>
        )}
        {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
          See more orders
        </Link> */}
      </React.Fragment>
    // </div>
  );
};

export default UserOrders;


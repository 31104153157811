import React from "react";
import "../../index.css"; // Import CSS file for styling
// import Strategy from "../../components/Strategies/Strategies";
import Dashboard from "../../components/DashboardComponent/Dashboard";
const DashboardPage = () => {
  return (
    <div className="dashboard-container" >
        <Dashboard />
    </div>
  );
};

export default DashboardPage;

export const fetchCandlesData = async (timestamp = 1723779900, token = 26000, time_frame = '5min',order_by='asc') => {
    const url = `https://localhost:8000/candles-data/?timestamp=${timestamp}&token=${token}&time_frame=${time_frame}&order_by=${order_by}`;
    const controller = new AbortController();
    const timeout = 10000
    const id = setTimeout(() => controller.abort(), timeout);
    try {
        const response = await fetch(url, {
            signal: controller.signal,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        if (error.name === 'AbortError') {
          console.error('Fetch request was aborted due to timeout');
        } else {
          console.error('Failed to fetch initial data:', error);
        }
      } finally {
        clearTimeout(id);
      }
};

export const transformCandlesData = (data) => {
    return data.map(item => ({
        time: new Date(item.timestamp).getTime() / 1000,
        open: item.open,
        high: item.high,
        low: item.low,
        close: item.close,
        volume: item.volume,
        open_interest: item.open_interest,
        candle_color: item.candle_color // Include candle_color if needed
    })).sort((a, b) => a.time - b.time); // Sort by timestamp in ascending order
};

export const createVolumeData = (candlesData) => {
    return candlesData.map(item => ({
        time: item.time,  // Assuming item.time is already a timestamp
        value: item.volume  // Extract volume directly from candlesData item
    }));
};

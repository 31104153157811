import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material"; // Import CircularProgress
import Profile from "./components/Profile/Profile.jsx";
import StrategyLeaderboard from "./components/StrategyLeaderboard/StrategyLeaderboard.jsx";
import ChartPage from "./pages/ChartPage/ChartPage.jsx";
// import SignUp from "./pages/SignUp/SignUp.jsx";
import HomePage from "./pages/HomePage/index.jsx";
import StrategyPage from "./pages/StrategyPage/StrategyPage.jsx";
import DashboardPage from "./pages/DashboardPage/DashboardPage.jsx";
import { useAuthContext } from './context/AuthContext.js'; // Import Auth Context
import GoogleAuthCallback from "./components/AuthProvider/GoogleAuthCallback.jsx";
import SignUpPopup from "./pages/SignUp/SignUp.jsx";
import AnalyticsPage from "./pages/AnalyticsPage/AnalyticsPage.jsx";
const AppRoutes = () => {
  const { user, isLoading } = useAuthContext();
  const [open, setOpen] = useState(false);


  if (isLoading) {
    // Use CircularProgress from MUI to show loading state
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  const isAuthenticated = !!user;
  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <HomePage /> : <HomePage />} />
      <Route path="/home" element={isAuthenticated ? <HomePage /> : <HomePage />} />
      <Route path="/Analytics" element={isAuthenticated ? <AnalyticsPage /> : <SignUpPopup open={open} setOpen={setOpen} />}  />
      <Route path="/strategy-leaderboard" element={isAuthenticated ? <StrategyLeaderboard /> : <SignUpPopup open={open} setOpen={setOpen} />} />
      <Route path="/dashboard" element={isAuthenticated ? <DashboardPage /> : <SignUpPopup open={open} setOpen={setOpen} />} />
      <Route path="/google/callback" element={<GoogleAuthCallback />} />
      <Route path="/chart" element={<ChartPage />} />
      <Route path="/strategies" element={isAuthenticated ? <StrategyPage /> : <SignUpPopup open={true} setOpen={setOpen} />} />
      <Route path="/profile" element={isAuthenticated ? <Profile /> : <SignUpPopup open={true} setOpen={setOpen} />} />
    </Routes>
  );
};

export default AppRoutes;

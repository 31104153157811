

import React, { useEffect, useRef, useState } from "react";
import ChartJS from "../../config/chartJsConfig";
import { fetchNseData } from "../../utils/services";
import { getToken } from "../../helpers";
import { Button, Box,Card, CssBaseline, useTheme } from "@mui/material";
import AppTheme from '../../theme/AppTheme';
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from '../../theme/customizations';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

const ScreenerChart = ({ nseCompanyData }) => {
  const authToken = getToken();
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [activeDays, setActiveDays] = useState(365);
  const [activeMetric, setActiveMetric] = useState("volume");
  const yValue = useState(750);

  const [CompanyData, setChartData] = useState(nseCompanyData || []);  // Default to an empty array if undefined
  const [ISIN, setIsin] = useState(() => nseCompanyData?.[0]?.isin || null);
  useEffect(() => {
    console.log("nseCompanydata",nseCompanyData);
  }, []);

  const getMetricData = () => {
    switch (activeMetric) {
      case "volume":
        return uniqueData.map((item) => parseInt(item.volume));
      // case "turnover":
      //   return uniqueData.map((item) => parseFloat(item.turnover));
      case "tot_trans":
        return uniqueData.map((item) => parseInt(item.tot_trans));
      case "del_per":
        return uniqueData.map((item) => parseFloat(item.del_perc));
      case "avg_trade_worth":
        return uniqueData.map((item) => parseFloat(item.avg_trade_worth));
      case "avg_qty_per_trade":
        return uniqueData.map((item) => parseInt(item.avg_qty_per_trade));
      default:
        return uniqueData.map((item) => parseInt(item.volume)); // Default is volume
    }
  };

  const sortedData = CompanyData.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const uniqueData = [];
  const seenDates = new Set();

  for (const item of sortedData) {
    const formattedDate = new Date(item.createdAt).toLocaleDateString("en-GB");
    if (!seenDates.has(formattedDate)) {
      seenDates.add(formattedDate);
      uniqueData.push(item);
    }
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { display: false },
        title: { display: true, text: "Date" },
      },
      y: {
        title: {
          display: true,
          text: "Price",
        },
        grid: { drawBorder: false },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            if (value >= 1_00_00_000) {
              return (value / 1_00_00_000).toFixed(1) + "Cr"; // Format as 1Cr, 2Cr, etc.
            } else if (value >= 10_00_000) {
              return (value / 10_00_000).toFixed(1) + "M"; // Format as 1M, 2M, etc.
            } else if (value >= 1_00_000) {
              return (value / 1_00_000).toFixed(1) + "L"; // Format as 1L, 2L, etc.
            } else if (value >= 10000) {
              return (value / 1000).toFixed(2) + "K"; // Format as 1K, 2K, etc.
            }
            return value; // Keep original value for smaller numbers
          },
        },
      },
      "metric-axis": {
        position: "right", // Aligns this axis to the right
        title: {
          display: true,
          text: activeMetric,
        },
        grid: {
          drawOnChartArea: false, // Ensures the metric axis doesn't overlap with the price grid lines
        },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            if (value >= 1_00_00_000) {
              return (value / 1_00_00_000).toFixed(1) + "Cr"; // Format as 1Cr, 2Cr, etc.
            } else if (value >= 10_00_000) {
              return (value / 10_00_000).toFixed(1) + "M"; // Format as 1M, 2M, etc.
            } else if (value >= 1_00_000) {
              return (value / 1_00_000).toFixed(1) + "L"; // Format as 1L, 2L, etc.
            } else if (value >= 1000) {
              return (value / 1000).toFixed(1) + "K";
            }
            return value; // Keep original value for smaller numbers
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
      horizontalLineHomepage: {
        yValue: yValue,
      },
    },
  };


  const chartData = {
    labels: uniqueData.map((item) =>
      new Date(item.createdAt).toLocaleDateString("en-GB")
    ),
    datasets: [
      {
        label: "Price",
        data: uniqueData.map((item) => item.close),
        fill: false,
        // type: "line",
        // yAxisID: "price-axis",
        // backgroundColor: 'transparent',
        backgroundColor: "rgb(173, 170, 216)",
        borderColor: "rgba(173, 170, 216, 1)",
        // lineTension: 0.8,
        pointRadius: 0, // Removes the dots
        borderWidth: 2.5, // Line thickness
        tension: .3,
      },
      {
        label: activeMetric,
        position: "right",
        data: getMetricData(),
        type: "bar",
        yAxisID: "metric-axis",
        backgroundColor: "rgb(190, 211, 253)",
        barPercentage: 0.6, // Adjust bar width
        categoryPercentage: 0.8, // Adjust category width
      },
    ],

  };

  const horizontalLinePluginHomepage = {
    id: "horizontalLineHomepage",
    beforeDraw: (chart) => {
      const { ctx, chartArea, scales, options } = chart;
      const yValue = options.plugins.horizontalLineHomepage.yValue; // Get yValue from chart options

      if (!chartArea || !scales || !scales.y || yValue === undefined) return;

      const { left, right } = chartArea;
      if (yValue < scales.y.min || yValue > scales.y.max) return;

      const yCoord = scales.y.getPixelForValue(yValue);

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(left, yCoord);
      ctx.lineTo(right, yCoord);
      ctx.lineWidth = 2;
      ctx.strokeStyle = "#22577A"; // Line color
      ctx.stroke();
      ctx.restore();
    },
  };

  ChartJS.register(horizontalLinePluginHomepage);


  useEffect(() => {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - activeDays);

    const formattedStartDate = startDate.toLocaleDateString('en-CA'); // YYYY-MM-DD format
    const formattedEndDate = today.toLocaleDateString('en-CA'); // YYYY-MM-DD format

    fetchNseData(authToken, ISIN, formattedStartDate, formattedEndDate)
      .then((data) => setChartData(data))
      .catch((error) => console.error("Error:", error));
  }, [activeDays, authToken, ISIN]);


  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    if (!chartData) return;
    setIsin(nseCompanyData?.[0]?.isin);

    const ctx = chartRef.current.getContext("2d");
    chartInstance.current = new ChartJS(ctx, {
      type: "line",
      data: chartData,
      options: chartOptions,
    });

    return () => chartInstance.current.destroy();
  }, [activeDays, activeMetric, nseCompanyData, CompanyData]);

  const handleDaysChange = (value) => setActiveDays(value);
  const handleMetricChange = (value) => setActiveMetric(value);

  return (
    <AppTheme themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />

      {/* <Paper id="chart" sx={{padding:1}}> */}
      <Card sx={{ height: '100%' }}>
        <Box
          id="chart-menu"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
            flexWrap: { xs: "wrap", md: "nowrap" }, // Wrap on smaller screens
            gap: "16px",
            // border:1,
            // background:'white'
          }}
        >
          <Box
            id="company-chart-days"
            className="options"
            sx={{
              display: "flex",
              flexWrap: "wrap", // Allow wrapping on smaller screens
              gap: "4px",
            }}
          >
            {[
              { label: "1w", value: 7 },
              { label: "1m", value: 30 },
              { label: "3m", value: 90 },
              { label: "6m", value: 180 },
              { label: "1Yr", value: 365 },
              { label: "3Yr", value: 1095 },
              { label: "5Yr", value: 1825 },
              { label: "Max", value: 10000 },
            ].map((item) => (
              <Button
                key={item.value}
                name="days"
                value={item.value}
                onClick={() => handleDaysChange(item.value)}
                variant={activeDays === item.value ? "contained" : "outlined"}
                size="small"
                sx={{
                  margin: "4px 0",
                  minWidth: "64px", // Ensure buttons have a consistent minimum size
                  textTransform: "none", // Keep button labels readable
                  fontSize: { xs: "10px", sm: "12px", md: "14px" }, // Adjust font size for smaller screens
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>

          <Box
            id="company-chart-metrics"
            className="options"
            sx={{
              display: "flex",
              flexWrap: "wrap", // Allow wrapping on smaller screens
              gap: "4px",
              // background:'white'
            }}
          >
            {[
              { label: "Volume", value: "volume" },
              { label: "Del Per", value: "del_per" },
              { label: "Total Transactions", value: "tot_trans" },
              { label: "Avg Trade Worth", value: "avg_trade_worth" },
              { label: "Avg Qty Per Trade", value: "avg_qty_per_trade" },
            ].map((item) => (
              <Button
                key={item.value}
                onClick={() => handleMetricChange(item.value)}
                name="metrics"
                value={item.value}
                variant={activeMetric === item.value ? "contained" : "outlined"}
                size="small"
                sx={{
                  margin: "4px 0",
                  minWidth: "64px",
                  textTransform: "none",
                  fontSize: { xs: "10px", sm: "12px", md: "14px" },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Box>

        <Box
          className="flex no-select"
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            // background:'white'
          }}
        >
          <canvas
            ref={chartRef}
            id="chart-canvas"
            style={{ width: "100%", height: "375px" }}
          ></canvas>
        </Box>

      </Card>
      {/* </Paper> */}
    </AppTheme>
  );

};

export default ScreenerChart;

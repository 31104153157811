import React from 'react';
import { API } from '../../constant';
import Button from '@mui/material/Button';
import { GoogleIcon } from '../../pages/SignUp/CustomIcons'; // Import the GoogleIcon
import { Box } from '@mui/system';
const provider = 'google';
const GoogleLoginButton = () => {
    const handleGoogleLogin = () => {
        window.location.href = `${API}/connect/${provider}`;
    };

    return (
        <Box
        paddingBottom={4}
        >
        <Button
            fullWidth
            variant="outlined"
            onClick={handleGoogleLogin}
            startIcon={<GoogleIcon />}
        >

            Sign Up with Google
        </Button>
        </Box>
    );
};

export default GoogleLoginButton;

import React from "react";
import "../../index.css";
// import Strategy from "../../components/Strategies/Strategies";
// import Strategies from "../../components/Strategies/Strategies";
import UserStrategyContent from "../../components/DashboardComponent/UserStrategyContent"
import StickyHeader from "../../components/common/StickyHeader";
const StrategyPage = () => {
  return (
    <div>
      <StickyHeader />

      <div className="strategy-container" >
        {/* <Strategies /> */}
        <UserStrategyContent />
      </div>
    </div>
  );
};

export default StrategyPage;

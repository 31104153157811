import React, { useState, useEffect } from 'react';
import { Container, Paper, TextField} from '@mui/material';
import { message, Spin } from "antd";

// import Title from '../DashboardComponent/Title';
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { getToken } from "../../helpers";


const CoreStrategies = () => {
  const [coreStrategies, setCoreStrategies] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);

      try {
        const authToken = getToken();
        const response = await fetch(`${API}/app-core-strategies?sort=id:asc`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log("core-strategies", responseData.data)
          if (responseData.data.length === 0) {
            message.info("No core-strategies available.");
          }
          else {

            setCoreStrategies(responseData.data);
          }
        } else {
          console.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  if (loading) {
    return <Spin size="large" />;
  }
  return (
    <Container>
      {coreStrategies.map((strategy, index) => {
        const { attributes } = strategy;
        const { data } = attributes;
        const overrideDataString = JSON.stringify(data, null, 2);

        return (
          <Paper
            key={index}
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              width: 'auto',
              marginBottom: 2 // Add some space between each strategy
            }}
          >
            {/* Display strategy attributes */}
            <TextField
              key={`name-${index}`}
              label="Name"
              value={attributes.name}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true, // Make the field read-only
              }}
            />
            <TextField
              key={`type-${index}`}
              label="Type"
              value={attributes.type}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              key={`category-${index}`}
              label="Category"
              value={attributes.category}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
            />
            {/* Display override_data */}
            <TextField
              key={`overrideData-${index}`}
              label="Override Data"
              value={overrideDataString} // Use the overrideDataString variable here
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
                multiline: true, // Enable multiline display
                rows: 6, // Adjust the number of rows based on your data
              }}
            />
          </Paper>
        );
      })}
    </Container>

  );
};

export default CoreStrategies;
import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MenuContent from './SideBarContent';
import { IconButton, useMediaQuery, useTheme, BottomNavigation, BottomNavigationAction, Stack, Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { Analytics } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const Drawer = styled(MuiDrawer)(({ theme, open, drawerWidth, drawerCollapsedWidth,isExpanded }) => ({
  width: open ? drawerWidth : drawerCollapsedWidth,
  // height: 200,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'fixed',
  top: theme.mixins.toolbar.minHeight ?? 17,
  left: 0,
  zIndex: theme.zIndex.drawer + 1,
  height: `calc(100vh - ${theme.mixins.toolbar.minHeight || 64}px)`,
  [`& .MuiDrawer-paper`]: {
    width: isExpanded ? drawerWidth : drawerCollapsedWidth,
    backgroundColor: theme.palette.background.paper,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: theme.shadows[4],
    // paddingTop: theme.mixins.toolbar.minHeight || 64,
  },
}));

export default function SideBar({ open, drawerWidth, drawerCollapsedWidth }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [mobileNavValue, setMobileNavValue] = useState(0);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) {
      setIsExpanded(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleSidebar = () => setIsExpanded((prev) => !prev);

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <>
      {!isMobile && (
        <Box>
          <Stack sx={{ position: 'relative' }}>
            <Drawer
              variant="permanent"
              isExpanded={isExpanded}
              open={open}
              drawerWidth={drawerWidth} 
              drawerCollapsedWidth={drawerCollapsedWidth}
              ref={sidebarRef}
              sx={{
                display: open ? 'block' : 'none',
                // transition: 'display 0.3s linear',
                transition: 'width 0.3s ease', // Smooth transition for width
                flexShrink: 0,
                alignItems: 'stretch',
              }}
            >
              <IconButton
                onClick={handleToggleSidebar}
                sx={{
                  position: 'absolute',
                  top: 3,
                  right: 3,
                  zIndex: 10,
                  color: (theme) => theme.palette.text.primary,
                  padding: 0,
                  minWidth: 0,
                  height: 24,
                  width: 24,
                }}
              >
                {isExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>

              <MenuContent isExpanded={isExpanded} onNavigate={handleNavigate} />
            </Drawer>
          </Stack>
        </Box>
      )}


      {isMobile && (
        

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1300,
            boxShadow: theme.shadows[4],
          }}
        >
          <BottomNavigation
            value={mobileNavValue}
            onChange={(event, newValue) => {
              setMobileNavValue(newValue);
              const routes = ['home', 'analytics' ,'search','settings'];
              handleNavigate(routes[newValue]);
            }}
            showLabels
          >
            <BottomNavigationAction label="Home" icon={<HomeIcon />} />
            <BottomNavigationAction label="Analytics" icon={<Analytics />} />
            <BottomNavigationAction label="Search" icon={<SearchIcon />} />
            <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
          </BottomNavigation>
        </Box>
      )}

    </>
  );
}

import { useEffect, useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getTSList } from '../../utils/services';
import { getToken } from '../../helpers';
import { Box, Typography, useMediaQuery, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function renderStatus(status) {
    const colors = {
        Weak: 'error',
        Strong: 'success',
    };

    return <Chip label={status} color={colors[status]} size="small" />;
}

const renderAvatar = (params) => {
    const value = params.value || '';
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={`https://avatars.dicebear.com/api/initials/${value.toUpperCase()}.svg`}
                alt={value}
                style={{ width: 32, height: 32, borderRadius: '50%', marginRight: 8 }}
            />
            {value}
        </div>
    );
};
const roundUpToTwoDecimal = (value) => Math.ceil(value * 100) / 100;

const TsListTable = () => {
    const [rows, setRows] = useState([]);
    const authToken = getToken();
    const isDesktop = useMediaQuery('(min-width: 960px)');
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        symbol: true,
        DayAvgQtyPerTrade: true,
        WeeklyAvgQtyPerTrade: true,
        MonthlyAvgQtyPerTrade: true,
        ThreeMonthAvgQtyPerTrade: false,
        SixMonthAvgQtyPerTrade: false,
        YearlyAvgQtyPerTrade: true,

        Week: true,
        Month: true,
        ThreeMonth: false,
        SixMonth: false,
        OneYear: true,
        status: true,
    });

    const [filterModel, setFilterModel] = useState({
        items: [
            {
                columnField: 'totalAverageScore',
                operatorValue: '>',
                value: 5,
            },
        ],
    });

    const columns = [
        {
            field: 'symbol',
            headerName: 'Symbol',
            flex: 1.5,
            minWidth: 150,
            renderCell: (params) => renderAvatar(params),
        },
        {
            field: 'DayAvgQtyPerTrade',
            headerName: '1D Qty',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'WeeklyAvgQtyPerTrade',
            headerName: '1W Qty',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'MonthlyAvgQtyPerTrade',
            headerName: '1M Qty',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'ThreeMonthAvgQtyPerTrade',
            headerName: '3M Qty',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'SixMonthAvgQtyPerTrade',
            headerName: '6M Qty',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'YearlyAvgQtyPerTrade',
            headerName: '1Y Qty',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'center',
            headerAlign: 'center',
        },

        {
            field: 'Week',
            headerName: '1W %',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'Month',
            headerName: '1M %',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'ThreeMonth',
            headerName: '3M %',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'SixMonth',
            headerName: '6M %',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'right',
            headerAlign: 'right',
        }, {
            field: 'OneYear',
            headerName: '1Y%',
            flex: 1,
            minWidth: isDesktop ? 100 : 140,
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 80,
            renderCell: (params) => renderStatus(params.value),
        },
    ];

    const handleFilterModelChange = (newFilterModel) => {
        if (JSON.stringify(newFilterModel) !== JSON.stringify(filterModel)) {
            setFilterModel(newFilterModel);
        }
    };
    const fetchData = async () => {
        try {
            const data = await getTSList(authToken);

            const mappedRows = data.map((company) => ({
                id: company.symbol,
                symbol: company.symbol,
                DayAvgQtyPerTrade: roundUpToTwoDecimal(company.averages?.['1_day']?.avg_qty_per_trade),
                WeeklyAvgQtyPerTrade: roundUpToTwoDecimal(company.averages?.['1_week']?.avg_qty_per_trade),
                MonthlyAvgQtyPerTrade: roundUpToTwoDecimal(company.averages?.['1_month']?.avg_qty_per_trade),
                ThreeMonthAvgQtyPerTrade: roundUpToTwoDecimal(company.averages?.['3_month']?.avg_qty_per_trade),
                SixMonthAvgQtyPerTrade: roundUpToTwoDecimal(company.averages?.['6_month']?.avg_qty_per_trade),
                YearlyAvgQtyPerTrade: roundUpToTwoDecimal(company.averages?.['1_year']?.avg_qty_per_trade),

                Week: roundUpToTwoDecimal(company.percentages?.['1_week_per']),
                Month: roundUpToTwoDecimal(company.percentages?.['1_month_per']),
                ThreeMonth: roundUpToTwoDecimal(company.percentages?.['3_month_per']),
                SixMonth: roundUpToTwoDecimal(company.percentages?.['6_month_per']),
                OneYear: roundUpToTwoDecimal(company.percentages?.['1_year_per']),

                // status: (company.averages?.['1_day']?.avg_qty_per_trade > company.averages?.['1_year']?.avg_qty_per_trade)
                //     ? 'Strong'
                //     : 'Weak',
                status: (company.percentages?.['1_year_per'] > 20)
                    ? 'Strong'
                    : 'Weak',
            }));


            setRows(mappedRows);
        } catch (error) {
            console.error('Error fetching scores:', error);
            setRows([]);
        }
    };

    useEffect(() => {
        if (!rows.length) {
          fetchData();
        }
      }, []);

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    paddingBottom: 1,
                }}
            >
                <Box flex="1" textAlign="right" display="flex" alignItems="center" justifyContent="flex-end">
                    <Typography variant="subtitle2">
                        Average Quantity Per Trade
                    </Typography>
                    <Tooltip title="This indicates the percentage difference between today's Average Trade Quantity and other periods average. Sort by higher Average Quantity and find Technically Bullish stocks by finding Higher Average Quantity in One Day.">
                        <IconButton
                            size="small"
                            sx={{
                                backgroundColor: 'transparent',
                                padding: 0,
                                marginLeft: 0.5,
                            }}
                        >
                            <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box flex="1" textAlign="right" display="flex" alignItems="center" justifyContent="flex-end">
                    <Typography variant="subtitle2">
                        Percentage % Difference with 1 Day Average Trade Worth
                    </Typography>
                    <Tooltip title="This indicates the percentage difference between today's trade worth and other periods average. Sort by higher percentage and find Technically Bullish stocks.">
                        <IconButton
                            size="small"
                            sx={{
                                backgroundColor: 'transparent',
                                padding: 0,
                                marginLeft: 0.5,
                            }}
                        >
                            <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>


            <DataGrid
                autoHeight
                checkboxSelection
                rows={rows}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                // components={{
                //     Toolbar: GridToolbar, // Add a toolbar to enable column selection
                // }}
                // componentsProps={{
                //     toolbar: {
                //         showQuickFilter: true,
                //         quickFilterProps: { debounceMs: 500 },
                //     },
                // }}

                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: 'OneYear', sort: 'desc' },
                        ],
                    },
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50]}
                disableColumnResize
                density="compact"
                slotProps={{
                    filterPanel: {
                        filterFormProps: {
                            logicOperatorInputProps: {
                                variant: 'outlined',
                                size: 'small',
                            },
                            columnInputProps: {
                                variant: 'outlined',
                                size: 'small',
                                sx: { mt: 'auto' },
                            },
                            operatorInputProps: {
                                variant: 'outlined',
                                size: 'small',
                                sx: { mt: 'auto' },
                            },
                            valueInputProps: {
                                InputComponentProps: {
                                    variant: 'outlined',
                                    size: 'small',
                                },
                            },
                        },
                    },
                }}
                filterModel={filterModel} // Apply the filter model here
                onFilterModelChange={handleFilterModelChange} // Update filter model state
            />
        </Box>
    );
};

export default TsListTable;

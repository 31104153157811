import React from 'react';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import Strategies from "../../components/Strategies/Strategies";
// import MoreIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
const UserStrategyContent = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
          <IconButton color="inherit">
            {/* <MoreIcon /> */}
          </IconButton>
            <Strategies />
      </Grid>
    </Grid>
  );
};

export default UserStrategyContent;

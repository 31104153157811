
import { createChart,ColorType} from 'lightweight-charts';
import React, { useRef, useEffect } from 'react';
// #-----------------------------------------------------------------

export function parseCSV(csvData) {
  const lines = csvData.trim().split('\n');
  const header = lines.shift().split(',');
  const data = [];
  lines.forEach(line => {
      const values = line.split(',');
      const entry = {};
      header.forEach((key, index) => {
          entry[key.trim()] = values[index].trim();
      });
      data.push(entry);
  });

  return data;
}

// Function to convert raw data to the format accepted by Lightweight Charts
export function convertIndexDailyData(data) {
  // Define a map to convert month names to month numbers
  // const monthMap = {
  //     "jan": "01", "feb": "02", "mar": "03", "apr": "04", "may": "05", "jun": "06",
  //     "jul": "07", "aug": "08", "sep": "09", "oct": "10", "nov": "11", "dec": "12"
  // };

  // Function to pad single digits with leading zero
  const padZero = (num) => (num < 10 ? '0' : '') + num;

  const convertedData = data.map(entry => {
      const parts = entry.datetime.split('-');
      const day = padZero(parseInt(parts[0]));
      // console.log('month',parts[1])
      const month = padZero(parseInt(parts[1]));   //padZero(monthMap[parts[1].toLowerCase()]);
      // console.log('converted month',month)
      const year = parts[2];
      const dateStr = `${year}-${month}-${day}`;

      return {
          time: dateStr,
          open: parseFloat(entry.open),
          high: parseFloat(entry.high),
          low: parseFloat(entry.low),
          close: parseFloat(entry.close),
          volume: parseInt(entry.volume),
          open_interest: parseInt(entry.open_interest),
          shares_traded: parseInt(entry.shares_traded),
          turnover: parseFloat(entry.turnover)
      };
  });
  return convertedData;
}

const colors = {
  backgroundColor: 'white',
  lineColor: '#2962FF',
  textColor: 'black',
  areaTopColor: '#2962FF',
  areaBottomColor: 'rgba(41, 98, 255, 0.28)',
};

export const Chart = ({ priceData, volumeData }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: 'solid', color: '#FFFFFF' },
        textColor: '#333333',
      },
      width: chartContainerRef.current.clientWidth,
      height: 300,
    });

    chart.timeScale().fitContent();

    const volumeSeries = chart.addHistogramSeries({
      color: 'rgba(75, 255, 181, 0.4)',
      lineWidth: 2,
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });
    volumeSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7,
        bottom: 0,
      },
    });
    volumeSeries.setData(volumeData);

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#4CAF50',
      borderUpColor: '#4CAF50',
      wickUpColor: '#4CAF50',
      downColor: '#E53935',
      borderDownColor: '#E53935',
      wickDownColor: '#E53935',
    });
    candleSeries.setData(priceData.map(item => ({
      time: item.time,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
    })));

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [priceData, volumeData]);

  return <div ref={chartContainerRef} style={{ height: '300px', width: '100%' }} />;
};

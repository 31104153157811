import React, { useContext } from "react";
import { Grid2, Grid, Container } from '@mui/material';
import SwitchCharts from "../HomePage/SwitchCharts";
import Dashboard from "../../components/HomeDashboard/Dashboard";
import { useTheme } from "@mui/material";
import HomePageContext from "../../context/HomePageContext";
import CompanySearch
    from "../../components/common/CompanySearch";
const AnalyticsPage = () => {
    const theme = useTheme();

    const {
        selectedCompany,
        companyList,
        handleCompanySelect,
    } = useContext(HomePageContext);


    return (
        
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                width: '100%',
                padding: 3,
                margin: 0,
                // marginTop: theme.spacing(8),
            }}
        >

            <Grid item xs={12} md={5} lg={4}
                paddingBottom={1}
                padding={1}
            >
                <CompanySearch
                    onCompanySelect={handleCompanySelect}
                    companyList={companyList}
                />

            </Grid>
            <Grid2 padding={0} paddingBottom={3}>
                <SwitchCharts />
            </Grid2>
            {/* <Dashboard company={selectedCompany} /> */}
        </Container>
    );
};

export default AnalyticsPage;











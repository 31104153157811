import React from "react";
import "../../index.css";
import HomeComponent from "./HomePage";
import { Container } from "@mui/system";
// import { useTheme } from "@mui/material";

const HomePage = () => {
  // const theme = useTheme();
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        width: '100%',
        padding: 3,
        margin: 0,
        // marginTop: theme.spacing(8),
      }}
    >
      <HomeComponent />
    </Container>

  );
};

export default HomePage;

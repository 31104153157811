import React  from 'react';
import { AppBar, Toolbar, Typography, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import CompanySearch from '../common/CompanySearch';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 16px',
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '60%',
  minWidth: '200px',
  width: '20vw', // Dynamically adjust width to 20% of the viewport
  [theme.breakpoints.down('sm')]: {
    width: '50vw', // Increase width for smaller screens
    minWidth: '200px',
  },
}));

const SearchHeaderBar = ({ companyList, onCompanySelect }) => {
  return (
    <StyledAppBar position="sticky">
      <StyledToolbar>

        <Typography
          variant="h6"
          noWrap
          component={Link}
          // to={"/strategy-leaderboard"}
          sx={{
            mr: 2,
            display: { xs: 'flex', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          Zinvest
        </Typography>

        <SearchContainer>
          <CompanySearch onCompanySelect={onCompanySelect} companyList={companyList} />
        </SearchContainer>

        {/* Right Section */}
        <Box />
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default SearchHeaderBar;






// import CompanySearch from '../common/CompanySearch';
// import CustomDatePicker from '../HomeDashboard/CustomDatePicker';

// import React, { useState } from 'react';
// import { AppBar, Toolbar, IconButton, InputBase, Button, Menu, MenuItem } from '@mui/material';
// // import { FaHome, FaSearch, FaTools, FaUserPlus, FaUser } from 'react-icons/fa';
// import { GiHamburgerMenu } from 'react-icons/gi';
// import { makeStyles } from '@mui/styles';
// import { styled, alpha } from '@mui/material/styles';
// import { Box } from '@mui/material';
// import { Search, Notifications, AccountCircle, ArrowDropDown, ArrowBack } from "@mui/icons-material";

// const useStyles = makeStyles((theme) => ({
//   searchInput: {
//     marginLeft: theme.spacing(1),
//     backgroundColor: 'white',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     width: '200px',
//   },
//   menuIcon: {
//     color: 'white',
//   },
//   navButton: {
//     color: 'white',
//   },
// }));

// const StyledToolbar = styled(Toolbar)(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   flexShrink: 0,
//   borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
//   backdropFilter: 'blur(24px)',
//   border: '1px solid',
//   borderColor: (theme.vars || theme).palette.divider,
//   backgroundColor: theme.vars
//     ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
//     : alpha(theme.palette.background.default, 0.4),
//   boxShadow: (theme.vars || theme).shadows[1],
//   padding: '8px 12px',
// }));


// const SearchHeaderBar = ({ children, companyList, onCompanySelect }) => {

//   const classes = useStyles();
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);

//   const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
//   const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

//   return (
//     <AppBar
//       position="sticky"
//       enableColorOnDark
//       sx={{
//         backgroundColor: 'transparent',
//         boxShadow: 'none',
//         backdropFilter: 'blur(10px)', // Optional: adds a blur effect for better readability
//       }}
//     >
//       <Toolbar
//         sx={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//         }}
//       >
//         {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//           <IconButton href="/" color="inherit">
//             <img
//               alt="algo-logo"
//               className="logo"
//               style={{ height: '40px' }}
//               src="/path-to-logo.png" // Update with the actual logo path
//             />
//           </IconButton>
//         </Box> */}

//         {/* Right Section */}
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             gap: 2,
//             flexGrow: 1,
//             justifyContent: 'flex-end',
//           }}
//         >
//           <CompanySearch
//             onCompanySelect={onCompanySelect}
//             companyList={companyList}
//             style={{
//               width: '100px',
//               maxWidth: '100%',
//             }}
//           />
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default SearchHeaderBar;

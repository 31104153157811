
import { API } from '../constant.js';
import axios from 'axios';

const apiRequest = async (method, endpoint, authToken, params = {}, data = {},timeout=10000) => {
  const url = `${API}${endpoint}`;
  console.log(`Making ${method} request to:`, url);

  try {
    const response = await axios({
      method,
      url,
      params,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      timeout: timeout,
    });
    // console.log("resp-data-inst", response);
    return response.data;
  } catch (error) {
    handleAPIError(error);
    throw error;
  }
};

export const fetchAllinst = async (authToken) => {
  return await apiRequest('GET', `/inst-data/all-inst`, authToken);
};

export const fetchNseData = async (authToken, isin, startDate = null, endDate = null) => {
  const params = {};
  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }
  try {
    return await apiRequest('GET', `/nse-data/isin/${isin}`, authToken, params);
  } catch (error) {
    console.error('Error fetching instance data:', error);
    throw error;
  }
};

export const fetchAverageData = async (authToken, isin, years = 3, startDate = null, endDate = null) => {

  const params = {};
  if (startDate && endDate) {
    params.start_date = startDate;
    params.end_date = endDate;
  }
  else {
    params.years = years;
  }
  try {
    return await apiRequest('GET', `/nse-data/average-data/${isin}`, authToken, params);
  } catch (error) {
    console.error('Error fetching instance data:', error);
    throw error;
  }

};

export const fetchAverageScore = async (authToken, symbol, finYear) => {
  console.log("finyear",finYear);
  const year = finYear ?? new Date().getFullYear() - 2;
  const params = {
    symbol: symbol,
    finYear: year
  };
  console.log("params", params);
  try {
    return await apiRequest('GET', `/inst-data/get-average-score`, authToken, params);
  } catch (error) {
    console.error('Error fetching AverageScores:', error);
    throw error;
  }
};

export const getFSList = async (authToken,finYear=null,minScore=null) => {

  // const year = finYear ?? new Date().getFullYear() - 3;
  // const score = minScore ?? 1;
  // const params = {
  //   score: score,
  //   finYear: year
  // };
  // console.log("params", params);
  const params={};
  try {
    return await apiRequest('GET', `/fs-company/get-company-list`, authToken, params);
  } catch (error) {
    console.error('Error fetching Company score list(getFSList):', error);
    throw error;
  }
};

export const getTSList = async (authToken,finYear=null,minScore=null) => {

  // const year = finYear ?? new Date().getFullYear() - 3;
  // const score = minScore ?? 1;
  // const params = {
  //   score: score,
  //   finYear: year
  // };
  // console.log("params", params);
  const params={};
  try {
    return await apiRequest('GET', `/ts-company/get-company-list`, authToken, params);
  } catch (error) {
    console.error('Error fetching Company Tech list(getTSList):', error);
    throw error;
  }
};


export const fetchAiInsights = async (authToken, isin) => {

  const params = {};
  const data={},timeout=30000;

  params.isin = isin;
  try {
    return await apiRequest('GET', `/inst-data/get-ai-analysis`, authToken, params,data,timeout);
  } catch (error) {
    console.error('Error fetching Ai insights:', error);
    throw error;
  }

};

const handleAPIError = (error) => {
  if (error.response) {
    console.error('Response status:', error.response.status);
    console.error('Response data:', error.response.data);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error:', error.message);
  }
};




// import * as React from 'react';
import { useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import ListAltIcon from '@mui/icons-material/ListAlt';
import CategoryIcon from '@mui/icons-material/Category';
import BarChartIcon from '@mui/icons-material/BarChart';

import AssignmentIcon from '@mui/icons-material/Assignment';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useState,useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DashboardContent from './DashboardContent';
import Orders from './Orders';
import UserStrategyContent from './UserStrategyContent';
import CoreStrategies from '../CoreStrategies/CoreStrategies';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.


const defaultTheme = createTheme();

export default function Dashboard() {

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    localStorage.setItem('selectedMenuItem', menuItem);
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState('Dashboard');


  const mainListItems = [
    {
      primary: 'Dashboard',
      icon: <DashboardIcon />,
      onClick: handleMenuItemClick,
    },
    {
      primary: 'Orders',
      icon: <ShoppingCartIcon />,
      onClick: handleMenuItemClick,
    },
    {
      primary: 'Core Strategies',
      icon: <CategoryIcon />,
      onClick: handleMenuItemClick,
    },
    {
      primary: 'My Strategies',
      icon: <ListAltIcon />,
      onClick: handleMenuItemClick,
    },
    {
      primary: 'Reports',
      icon: <BarChartIcon />,
      onClick: handleMenuItemClick,
    },
  ];


  const secondaryListItems = [
    {
      primary: 'Current month',
      icon: <AssignmentIcon />,
      onClick: handleMenuItemClick,
    },
    {
      primary: 'Last quarter',
      icon: <AssignmentIcon />,
      onClick: handleMenuItemClick,
    },
    {
      primary: 'Year-end sale',
      icon: <AssignmentIcon />,
      onClick: handleMenuItemClick,
    },
  ];


  // Render different components based on the selectedMenuItem state
  const renderComponent = () => {
    switch (selectedMenuItem) {
      case 'Dashboard':
        return <DashboardContent />;
      case 'Orders':
        return <Orders />;
      case 'My Strategies':
        return <UserStrategyContent />;
      case 'Core Strategies':
        return <CoreStrategies />;
      default:
        return <DashboardContent />;
    }
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };


  useEffect(() => {
    // Retrieve selected menu item from localStorage when component mounts
    const storedMenuItem = localStorage.getItem('selectedMenuItem');
    if (storedMenuItem) {
      setSelectedMenuItem(storedMenuItem);
    } else {
      // Set default menu item if none is stored in localStorage
      setSelectedMenuItem('Dashboard');
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute"
          open={open}
          sx={{
            // top: '72px', // Adjust the top position to be below the default AppBar
            zIndex: theme => theme.zIndex.drawer + 1, // Ensure the AppBar is above the Drawer
          }}
        >

          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {selectedMenuItem}
            </Typography>

            <Button className='dashboard-home-button' onClick={() => navigate("/home", { replace: true })}>
              <Typography>Home</Typography></Button>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>

        </AppBar>

        {/* -----------end of app bar----------- */}
        <Drawer
          variant="permanent"
          open={!open}
          sx={{
            width: drawerWidth, // Define the width of the drawer
            flexShrink: 0, // Prevent the drawer from shrinking
            '& .MuiDrawer-paper': {
              width: drawerWidth, // Set the width of the drawer paper
              boxSizing: 'border-box', // Ensure that padding and border are included in the width
            },
          }}

        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          {/* ------------icon button end -----------*/}
          <Divider />


          {mainListItems.map((item, index) => (
            <ListItemButton
              key={index}
              onClick={() => item.onClick(item.primary)}
              sx={{
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.primary} />
            </ListItemButton>
          ))}
          <Divider sx={{ my: 1 }} />
          <ListSubheader component="div" inset>
            Saved reports
          </ListSubheader>

          {secondaryListItems.map((item, index) => (
            <ListItemButton key={index}
              onClick={() => item.onClick(item.primary)}
              sx={{
                paddingTop: '8px',
                paddingBottom: '8px',
              }}

            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.primary} />
            </ListItemButton>
          ))}

        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >

          {/* ----------------start of dashboard menu list items */}
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>

            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                {/* {selectedMenuItem === 'Dashboard' && <DashboardContent />} */}
                {renderComponent()}

              </Grid>
            </Container>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

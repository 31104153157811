import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export default function ResponsiveDatePickers({ onDateChange }) {

  const [selectedDate, setSelectedDate] = React.useState(dayjs());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date.format('YYYY-MM-DD');
    onDateChange(formattedDate); // Execute the callback function with the selected date
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

          <DatePicker 
          defaultValue={dayjs()}
          value={selectedDate}
          onChange={handleDateChange}
          
          />

    </LocalizationProvider>
  );
}
// import { createContext, useContext } from "react";

// export const AuthContext = createContext({
//   user: undefined,
//   isLoading: false,
//   setUser: () => {},
// });

// export const useAuthContext = () => useContext(AuthContext);


import React, { createContext, useContext, useState, useEffect } from 'react';
import { getToken } from "../helpers.js";
export const AuthContext = createContext({
  user: undefined,
  isLoading: false,
  setUser: () => {},
  signIn: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if (token) {
      setUser({ token });
    }
    setIsLoading(false);
  }, []);

  const signIn = (token) => {
    setUser({ token });
    localStorage.setItem('token', token);
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, setUser, signIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

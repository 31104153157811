import * as React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  Dialog,
  DialogContent,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, message, Spin } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { API } from '../../constant';
import { setToken } from '../../helpers';
import { GoogleIcon } from './CustomIcons';
import AppTheme from '../../theme/AppTheme';
import GoogleLoginButton from '../../components/common/GoogleButton';
// const defaultTheme = createTheme();
// 

const SignUpPopup = ({ open, setOpen }) => {
  // const [modal, setOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false); // Track checkbox state
  const [isSignup, setIsSignup] = useState(false); // Track signup form visibility
  const navigate = useNavigate();
  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [emailExists, setEmailExists] = useState(false);
  const [username, setUsername] = useState('');
  const [usernameExists, setUsernameExists] = useState(false);


  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); 
  };

  const checkEmailExists = async () => {
    if(isSignup){
    try {
      const response = await fetch(`${API}/users?filters[email][$eq]=${email}`);
      const data = await response.json();
      setEmailExists(data.length > 0);
    } catch (error) {
      console.error('Error checking email:', error);
    }
  }
  };
  const checkUsernameExists = async () => {
    if(isSignup){
    try {
      const response = await fetch(`${API}/users?filters[username][$eq]=${username}`);
      const data = await response.json();
      setUsernameExists(data.length > 0);
    } catch (error) {
      console.error('Error checking username:', error);
    }
  }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (isSignup) {
      debouncedCheckEmailExists();
    }
  };
  
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    if (isSignup) {
      debouncedCheckUsernameExists();
    }
  };
  
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  
  const debouncedCheckEmailExists = debounce(checkEmailExists, 1000);
  const debouncedCheckUsernameExists = debounce(checkUsernameExists, 1000);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData(event.currentTarget);
      const firstname = formData.get('firstname');
      const lastname = formData.get('lastname');
      const username = formData.get('username');
      const email = formData.get('email');
      const password = formData.get('password');

      if (isSignup) {
        const authdata = { firstname, lastname, username, email, password };
        const response = await fetch(`${API}/auth/local/register`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(authdata),
        });

        const data = await response.json();
        if (data?.error) {
          throw data?.error;
        } else {
          setToken(data.jwt);
          setUser(data.user);
          message.success(`Welcome to Algo-Trader ${data.user.username}!`);
          navigate('/profile', { replace: true });
          setOpen(false); // Close the popup after successful signup
        }
      } else {
        // Handle signin
        const authdata = { identifier: email, password };
        const response = await fetch(`${API}/auth/local`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(authdata),
        });

        const data = await response.json();
        if (data?.error) {
          throw data?.error;
        } else {
          setToken(data.jwt);
          setUser(data.user);
          message.success(`Welcome back, ${data.user.username}!`);
          navigate('/profile', { replace: true });
          setOpen(false); // Close the popup after successful signin
        }
      }
    } catch (error) {
      console.error(error);
      setError(error?.message ?? 'Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleForm = () => {
    setError('');
    setIsSignup(!isSignup);
  };

  return (
    <AppTheme disableCustomTheme={false}>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            console.log("if reason");
            setOpen(false);
          }
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          {/* <ThemeProvider theme={defaultTheme}> */}
          <Container component="main">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError('')}
              />
            ) : null}
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {isSignup ? 'Sign up' : 'Sign in'}
              </Typography>
              
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                {/* {isLoading && <Spin size="small" />}
                <Grid container spacing={2}>
                  {isSignup && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstname"
                          required
                          fullWidth
                          id="firstname"
                          label="First Name"
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastname"
                          label="Last Name"
                          name="lastname"
                          autoComplete="family-name"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="username"
                      label="User Name"
                      name="username"
                      autoComplete="user-name"
                      onBlur={checkUsernameExists}
                      error={usernameExists}
                      helperText={usernameExists ? 'Username Already Taken' : ''}
                      onChange={handleUsernameChange}
                      disabled={!isSignup}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      onBlur={checkEmailExists}
                      error={emailExists}
                      helperText={emailExists ? 'Email address already registered' : ''}
                      onChange={handleEmailChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  {isSignup && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="allowExtraEmails"
                            color="primary"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Agree to T&C"
                      />
                    </Grid>
                  )}
                </Grid> */}
                {/* <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!isChecked && isSignup}
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: isChecked ? 'primary.main' : 'grey.500', 
                    '&:hover': {
                      backgroundColor: isChecked ? 'primary.dark' : 'grey.600',
                    },
                  }}
                >
                  {isSignup ? 'Sign Up' : 'Sign In'}
                </Button> */}

                <GoogleLoginButton />
                
                {/* <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link variant="body2" onClick={toggleForm}>
                      {isSignup ? 'Already have an account? Sign in' : 'Need an account? Sign up'}
                    </Link>
                  </Grid>
                </Grid> */}
                
              </Box>
            </Box>
          </Container>
          {/* </ThemeProvider> */}
        </DialogContent>
      </Dialog>
    </AppTheme>
  );
};

export default SignUpPopup;













// import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// // import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { GoogleIcon} from './CustomIcons';
// import LoginContent from './LoginPopup';

// import {Alert,message,Spin,} from "antd";
// import  { useState } from "react";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { useAuthContext } from "../../context/AuthContext";
// // import useScreenSize from "../../hooks/useScreenSize";
// import { API } from "../../constant";
// import { setToken } from "../../helpers";

// const defaultTheme = createTheme();

// const SignUp = () => {


//   // const { isDesktopView } = useScreenSize();
//   const navigate = useNavigate();

//   const { setUser } = useAuthContext();

//   const [isLoading, setIsLoading] = useState(false);

//   const [error, setError] = useState("");


//   const [email, setEmail] = useState('');
//   const [emailExists, setEmailExists] = useState(false);

//   const handleEmailChange = (event) => {
//     setEmail(event.target.value);
//   };

//   const checkEmailExists = async () => {
//     try {
//       const response = await fetch(`${API}/users?filters[email][$eq]=${email}`);
//       const data = await response.json();
//       setEmailExists(data.length > 0);
//     } catch (error) {
//       console.error('Error checking email:', error);
//     }
//   };

//   const [username, setUsername] = useState('');
//   const [usernameExists, setUsernameExists] = useState(false);

//   const handleUsernameChange = (event) => {
//     setUsername(event.target.value);
//   };

//   const checkUsernameExists = async () => {
//     try {
//       const response = await fetch(`${API}/users?filters[username][$eq]=${username}`);
//       const data = await response.json();
//       setUsernameExists(data.length > 0);
//     } catch (error) {
//       console.error('Error checking username:', error);
//     }
//   };

//   const handleSubmit = async (event) => {

//     event.preventDefault();
//     setIsLoading(true);
//     try {

//       const formData = new FormData(event.currentTarget);
//       const firstname = formData.get('firstname')
//       const lastname = formData.get('lastname')
//       const username = formData.get('username')
//       const email = formData.get('email');
//       const password = formData.get('password');

//       const authdata = {
//         firstname: firstname,
//         lastname: lastname,
//         username: username,
//         email: email,
//         password: password,
//       };

//       const response = await fetch(`${API}/auth/local/register`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(authdata),
//       });

//       const data = await response.json();
//       if (data?.error) {
//         throw data?.error;
//       } else {
//         // set the token
//         setToken(data.jwt);

//         // set the user
//         setUser(data.user);

//         message.success(`Welcome to Algo-Trader ${data.user.username}!`);

//         navigate("/profile", { replace: true });
//       }
//     } catch (error) {
//       console.error(error);
//       setError(error?.message ?? "Something went wrong!");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Container component="main" maxWidth="xs">
//             {error ? (
//               <Alert
//                 className="alert_error"
//                 message={error}
//                 type="error"
//                 closable
//                 afterClose={() => setError("")}
//               />
//             ) : null}
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign up
//           </Typography>
//           <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
//             {isLoading && <Spin size="small" />}
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   autoComplete="given-name"
//                   name="firstname"
//                   required
//                   fullWidth
//                   id="firstname"
//                   label="First Name"
//                   autoFocus
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   required
//                   fullWidth
//                   id="lastname"
//                   label="Last Name"
//                   name="lastname"
//                   autoComplete="family-name"
//                 />
//               </Grid>

//               <Grid item xs={12}>
//                 <TextField
//                   required
//                   fullWidth
//                   id="username"
//                   label="User Name"
//                   name="username"
//                   autoComplete="user name"
//                   onBlur={checkUsernameExists}
//                   error={usernameExists}
//                   helperText={usernameExists ? 'Username Already Taken' : ''}
//                   onChange={handleUsernameChange}
//                 />
//               </Grid>

//               <Grid item xs={12}>
//                 <TextField
//                   required
//                   fullWidth
//                   id="email"
//                   label="Email Address"
//                   name="email"
//                   autoComplete="email"
//                   onBlur={checkEmailExists}
//                   error={emailExists}
//                   helperText={emailExists ? 'Email address already registered' : ''}
//                   onChange={handleEmailChange}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   required
//                   fullWidth
//                   name="password"
//                   label="Password"
//                   type="password"
//                   id="password"
//                   autoComplete="new-password"
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <FormControlLabel
//                   control={<Checkbox value="allowExtraEmails" color="primary" />}
//                   label="agree t&c"
//                 />
//               </Grid>
//             </Grid>
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//             >
//               Sign Up
//             </Button>
//             <Button
//           fullWidth
//           variant="outlined"
//           onClick={() => alert('Sign in with Google')}
//           startIcon={<GoogleIcon />}
//         >
//           Sign in with Google
//         </Button>
//             <Grid container justifyContent="flex-end">
//               <Grid item>
//                 <Link to="/signin" variant="body2">
//                   Already have an account? Sign in
//                 </Link>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//       </Container>
//     </ThemeProvider>
//   );

// };

// export default SignUp;

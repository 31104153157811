import React, { useContext, useEffect } from 'react';
import { Grid, Typography, Paper, Box, Card } from '@mui/material';
import CompanySearch from '../../components/common/CompanySearch.jsx';
import HomePageContext from '../../context/HomePageContext.jsx';
import ScreenerChart from '../../components/Chart/ScreenerChart.jsx';

import OpenAiAnalyze from '../../components/HomeDashboard/OpenAiAnalyzeCard.jsx';
import FsListTable from '../../components/HomeDashboard/FundFilterDataGrid.jsx';
import TsListTable from '../../components/HomeDashboard/TechFilterDataGrid.jsx';
import { useSearchParams } from 'react-router-dom';

const HomeComponent = () => {

  const {
    selectedCompany,
    companyList,
    handleCompanySelect,
    nseCompanyData
  } = useContext(HomePageContext);

  useEffect(() => {
    console.log("nseCompanydata inside",nseCompanyData);
  }, []);

  return (
    <>
      {/* <Paper sx={{ padding: 1, marginBottom: 3 }}> */}
      <Card>
        <Typography variant="h4" sx={{ color: '#333', marginBottom: 1 }}>
          AI-Based Company Share Analysis
        </Typography>
        <Typography variant="body1" sx={{ color: '#555' }}>
          Leverage AI to analyze company performance based on technical and fundamental data.
        </Typography>
        </Card>
      {/* </Paper> */}

      <Grid container spacing={0}>
        {/* Company Search Section */}
        <Grid item xs={12} md={5} lg={4}
          paddingBottom={1}
          padding={1}
        >

          <CompanySearch onCompanySelect={handleCompanySelect} companyList={companyList} />
        </Grid>

        {nseCompanyData && (
          <Box
            sx={{
              // padding: 2,
              position: 'relative',
              width: '100%',
              // border:.3,
              borderRadius: '8px',

            }}
          >

            <ScreenerChart nseCompanyData={nseCompanyData} />
          </Box>
        )}
      </Grid>

      <Box
        sx={{
          marginBottom: 2,
          marginTop: 2,
          
          borderRadius: '8px',
        }}>
        <OpenAiAnalyze company={selectedCompany} />
      </Box>

      {/* <Typography
        variant="h6"
        sx={{
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          fontSize: { xs: '1rem', md: '1.5rem' },
          textAlign: { xs: 'center', md: 'left' },
          marginBottom: 1,
        }}
      > */}
      <Typography variant="h4" sx={{ color: '#333', marginBottom: 1 }}>
        Technically Bullish
      </Typography>
      <Box sx={{
        overflowX: 'auto',
        // border:.5,
        borderRadius: '8px',
      }}>
        <TsListTable />
      </Box>

      <Box>
        {/* <Typography
          variant="h6"
          sx={{
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            fontSize: { xs: '1rem', md: '1.5rem' },
            textAlign: { xs: 'center', md: 'left' },
            marginBottom: 1,
          }}
        > */}
        <Typography variant="h4" sx={{ color: '#333', marginBottom: 1 }}>
          Fundamental-Score
        </Typography>
        <Box sx={{
          overflowX: 'auto',
          // border:1,
          borderRadius: '8px',
          paddingBottom:3
        }}>
          <FsListTable />
        </Box>
      </Box>
      {/* </Box> */}

    </>
  );

};

export default HomeComponent;

import React, { useState, useContext } from 'react';
import { Tabs, Tab, Box, Fade, Grid } from '@mui/material';
import { AveragesBarChartV2 } from '../../components/Chart/AverageChart/AverageBarRechart';
import ScreenerChart from '../../components/Chart/ScreenerChart';
import HomePageContext from '../../context/HomePageContext';
import TsListTable from '../../components/HomeDashboard/TechFilterDataGrid';
import FsListTable from '../../components/HomeDashboard/FundFilterDataGrid';

export default function SwitchCharts() {
    const [tabValue, setTabValue] = useState(0);
    const { averagesData, nseCompanyData } = useContext(HomePageContext);



    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{ marginBottom: 3, borderBottom: 1, borderColor: 'divider' }}
                textColor="primary"
                indicatorColor="primary"
                centered
            >
                <Tab label="Technical Analysis" />
                <Tab label="Fundamental Analysis" />

            </Tabs>

            <Fade in={tabValue === 0} timeout={500}>
                <Box hidden={tabValue !== 0} marginTop={2}>

                    {nseCompanyData && nseCompanyData.length > 0 && (
                        // <ScreenerChart nseCompanyData={nseCompanyData} />
                        <Box
                            sx={{
                                // padding: 2,
                                position: 'relative',
                                width: '100%',
                                borderRadius: '8px',

                            }}
                        >

                            <ScreenerChart nseCompanyData={nseCompanyData} />
                        </Box>
                    )}

                    {averagesData && (
                        <AveragesBarChartV2 data={averagesData} />
                    )}
                    <Box
                        sx={{
                            // padding: 2,
                            // paddingTop: 1,
                            position: 'relative',
                            width: '100%',
                            borderRadius: '8px',
                        }}
                    >
                        <TsListTable />
                    </Box>

                </Box>
            </Fade>

            <Fade in={tabValue === 1} timeout={500}>


                <Box hidden={tabValue !== 1}
                    // marginTop={2}
                    sx={{
                        position: 'relative',
                        width: '100%',
                        borderRadius: '8px',
                    }}
                >
                    <FsListTable />
                </Box>
            </Fade>
        </>
    );
}
